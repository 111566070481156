import React from "react";
import { useHistory } from "react-router";
import Flex from "app/components/Flex";
import Button from "app/components/Button";
import { P1 } from "app/components/Typography";
import abbreviateCount from "helpers/abbreviateCount";
import { VerticalBar } from "app/components/VerticalBar";
import challengesDaysLeft from "helpers/challengesDaysLeft";

export default ({ challenge }) => {
  const history = useHistory();
  const { id, submissionsCount, isActive } = challenge;
  const daysLeft = challengesDaysLeft(challenge);
  const abbreviatedSubmissionsCount = abbreviateCount(submissionsCount);

  const daysLeftText = `day${daysLeft > 1 ? "s" : ""} left!`;

  return (
    <Flex
      position="absolute"
      bottom="78px"
      left="18px"
      flexDirection="column"
      color="white"
      display={{ _: "flex", sm: "none" }}
      width="80%"
    >
      <Flex flexWrap="wrap">
        <P1 color="white" fontWeight="500">
          <b>{abbreviatedSubmissionsCount}&nbsp;</b>
          SUBMISSIONS&nbsp;&nbsp;
        </P1>
        {isActive && (
          <>
            <VerticalBar />
            <P1 fontWeight="600" color="gold">
              &nbsp;&nbsp;
              {isActive ? `${daysLeft} ${daysLeftText}` : "COMPLETE"}
            </P1>
          </>
        )}
      </Flex>
      <Button
        mt="22px"
        width={{ _: "85%", xs: "95%" }}
        height="33px"
        onClick={e => {
          e.stopPropagation();
          history.push(`/challenges/${id}`);
        }}
      >
        VIEW CHALLENGE
      </Button>
    </Flex>
  );
};
