import React from "react";
import PropTypes from "prop-types";
import { VideoPlayer } from "..";

const ChallengeVideoPlayer = ({
  url,
  isChallenge,
  classId,
  likesCount,
  commentsCount,
  isPlayingFromParent,
  setPlayingFromParent = () => {},
  onLike = () => {},
  didLike,
  onClickComment = () => {},
  thumbnailUrl,
  ...rest
}) => (
  <VideoPlayer
    isChallenge={isChallenge}
    thumbnailUrl={thumbnailUrl}
    classId={classId}
    url={url}
    likesCount={likesCount}
    commentsCount={commentsCount}
    hideMenuBtn
    startMuted={false}
    isPlayingFromParent={isPlayingFromParent}
    setPlayingFromParent={setPlayingFromParent}
    onLike={onLike}
    didLike={didLike}
    onClickComment={onClickComment}
    {...rest}
  />
);

ChallengeVideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string,
  isChallenge: PropTypes.bool.isRequired,
  classId: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired,
  likesCount: PropTypes.number,
  commentsCount: PropTypes.number,
  isPlayingFromParent: PropTypes.bool,
  setPlayingFromParent: PropTypes.func,
  onLike: PropTypes.func,
  didLike: PropTypes.bool,
  onClickComment: PropTypes.func,
};

export default ChallengeVideoPlayer;
