import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ANALYTICS_MODULE } from "constants/index";
import Flex from "app/components/Flex";
import ChallengeVideoPlayer from "app/components/Challenges/ChallengeVideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import { setWidgetPlaying } from "modules/community";
import {
  useActivityReactionCreate,
  useActivityReactionDelete,
} from "hooks/Activities";
import env from "helpers/env";
import ChallengeInfoXS from "./ChallengeInfoXS";

export default ({ challenge }) => {
  const {
    id: challengeId,
    forClass: { preview_url, thumbnail, id: classId } = {},
    comments: { totalCount: commentsCount } = {},
    reactions = [],
    userReactions = [],
  } = challenge;
  const dispatch = useDispatch();
  const { challengeIdPlaying } = useSelector(({ community }) => community);
  const activityReactionCreate = useActivityReactionCreate();
  const activityReactionDelete = useActivityReactionDelete();
  const thumbnailUrl = `${env(
    "PUBLIC_ASSET_PATH"
  )}/class-thumbnails/${thumbnail}-basic.jpg`;

  const history = useHistory();
  const location = useLocation();

  const setPlayingFromParent = isPlaying =>
    isPlaying
      ? dispatch(setWidgetPlaying(challengeId))
      : dispatch(setWidgetPlaying(null));

  const [{ totalCount: likesCount = 0 } = {}] = reactions.filter(
    ({ reactionType } = {}) => reactionType === "like"
  );

  const didLike = userReactions.includes("like");

  const handleLikeToggle = async () => {
    const createOrDeleteFn = didLike
      ? activityReactionDelete
      : activityReactionCreate;

    await createOrDeleteFn({
      activityId: challengeId,
      activityType: "challenge",
      reactionType: "like",
    });

    return !didLike;
  };

  const openModal = () => {
    setPlayingFromParent(false);
    history.push({
      pathname: `/challenges/post/${challengeId}`,
      state: {
        modal: true,
        challengeId,
        previousLocation: location,
        fromModule: ANALYTICS_MODULE.community_feed,
      },
    });
  };

  return (
    <Flex
      position="relative"
      flexDirection="column"
      alignItems="center"
      height={{ _: "469px", xs: "621px", lg: "647px" }}
      width={{ _: "259px", xs: "343px", lg: "357px" }}
      overflowY="hidden"
      overflowX="hidden"
      borderRadius="16px"
      onClick={openModal}
    >
      <ChallengeVideoPlayer
        isChallenge
        classId={classId}
        url={preview_url}
        thumbnailUrl={thumbnailUrl}
        likesCount={likesCount}
        commentsCount={commentsCount}
        containerBg="monochrome.1"
        isPlayingFromParent={challengeIdPlaying === challengeId}
        setPlayingFromParent={setPlayingFromParent}
        onLike={handleLikeToggle}
        didLike={didLike}
        onClickComment={openModal}
        challenge={challenge}
      />
      <ChallengeInfoXS challenge={challenge} />
    </Flex>
  );
};
